import { Action, GameStages, GameState, PlayerWithOptionalId, Stage } from './types';
import _ from 'lodash';
import { mergerLog } from './logger';

type StageAction = {
    order?: number;
    player: PlayerWithOptionalId;
    action: Action;
};
const calculateSubtype = (act: StageAction, oldStage: Stage): 'call' | 'push' | 'raise' | undefined => {
    if (act.action.type !== 'bet') {
        return undefined;
    }
    const wasSameBet = oldStage.actions.filter(a =>
            a.action.type === 'bet'
            && (
                a.action.amount === act.action.amount
                || (a.action.amount > act.action.amount && act.player.stack === 0)
            )
            && (a.player.name !== act.player.name || a.player.slot !== a.player.slot)
    ).length > 0;
    if (wasSameBet) {
        return 'call';
    }
    const oldBets = oldStage.actions.filter(a => a.action.type === 'bet');
    const isMaxBet = oldBets.filter(a => a.action.amount < act.action.amount).length === oldBets.length;
    if (isMaxBet) {
        return 'raise';
    }
    return act.player.stack === 0 ? 'push' : undefined;
}
const mergeStage = (oldStage: Stage, newStage: Stage): Stage => {
    const actions: StageAction[] = [];
    const wonActions: StageAction[] = [];
    oldStage.actions.forEach(value => {
        if (value.action.type === 'won') {
            wonActions.push(value);
            return;
        }
        actions.push({order: actions.length, ...value});
    });
    newStage.actions.forEach(act => {
        const existed = actions.find(a =>
            a.action.type === act.action.type
            && a.action.amount === act.action.amount
            && a.player.name === act.player.name
            && a.player.slot == act.player.slot
        );
        if (existed) {
            existed.player = updatePlayerInAction(existed, act);
            return;
        }
        if (act.action.type === 'won') {
            wonActions.push(act);
            return;
        }
        if (!act.action.subType) {
            act.action.subType = calculateSubtype(act, oldStage);
        }
        actions.push({order: actions.length, ...act});
    });
    wonActions.forEach(act => {
        const existed = actions.find(a =>
            a.action.type === act.action.type
            && a.player.name === act.player.name
            && a.player.slot === act.player.slot
        );
        if (existed) {
            existed.player = updatePlayerInAction(existed, act);
            return;
        }
        actions.push({order: actions.length, ...act});
    });
    return {
        pot: oldStage.pot,
        actions: actions
    };
}

const updatePlayerInAction = (oldAction: StageAction, newAction: StageAction): PlayerWithOptionalId => {
    const {position, ...rest} = newAction.player;
    return {
        position: oldAction.player.position,
        ...rest
    }
}

const mergeStages = (oldStages: GameStages, newStages: GameStages): GameStages => {
    const mergedStages = {...oldStages};
    const unrepeatableActions = (stage: Stage) => stage.actions.filter(a => !['won', 'fold'].includes(a.action.type));
    const tooManyActionsInOneStep = (oldStage: Stage, newStage: Stage) =>
        oldStage.actions.length === 0 && unrepeatableActions(newStage).length > 1;
    if (oldStages.preFlop && newStages.preFlop) {
        mergedStages.preFlop = mergeStage(oldStages.preFlop, newStages.preFlop);
    }
    if (oldStages.flop && newStages.flop && !tooManyActionsInOneStep(oldStages.flop, newStages.flop)) {
        const stage = mergeStage(oldStages.flop, newStages.flop);
        mergedStages.flop = {
            cards: newStages.flop.cards,
            actions: stage.actions,
            pot: stage.pot,
        };
    }
    if (oldStages.turn && newStages.turn && !tooManyActionsInOneStep(oldStages.turn, newStages.turn)) {
        const stage = mergeStage(oldStages.turn, newStages.turn);
        mergedStages.turn = {
            card: newStages.turn.card,
            actions: stage.actions,
            pot: stage.pot
        };
    }
    if (oldStages.river && newStages.river && !tooManyActionsInOneStep(oldStages.river, newStages.river)) {
        const stage = mergeStage(oldStages.river, newStages.river);
        mergedStages.river = {
            card: newStages.river.card,
            actions: stage.actions,
            pot: stage.pot
        };
    }
    if (!oldStages.preFlop && newStages.preFlop) {
        mergedStages.preFlop = newStages.preFlop;
    }

    if (!oldStages.flop && newStages.flop) {
        if (unrepeatableActions(newStages.flop).length === 1) {
            mergedStages.flop = newStages.flop;
        } else {
            mergedStages.flop = {
                actions: [],
                pot: newStages.flop.pot,
                cards: newStages.flop.cards
            }
        }

    }
    if (!oldStages.turn && newStages.turn) {
        if (unrepeatableActions(newStages.turn).length === 1) {
            mergedStages.turn = newStages.turn;
        } else {
            mergedStages.turn = {
                actions: [],
                pot: newStages.turn.pot,
                card: newStages.turn.card
            }
        }
    }
    if (!oldStages.river && newStages.river) {
        if (unrepeatableActions(newStages.river).length === 1) {
            mergedStages.river = newStages.river;
        } else {
            mergedStages.river = {
                actions: [],
                pot: newStages.river.pot,
                card: newStages.river.card
            }
        }
    }
    return removeFoldDuplicates(mergedStages);
}

const removeFoldDuplicates = (stages: GameStages): GameStages => {
    if (!stages.flop) {
        return stages;
    }
    type Fold = {
        name: string,
        slot: number
    }
    const folds = (stage?: Stage): Fold[] => stage?.actions
        .filter(a => a.action.type === 'fold')
        .map(a => ({name: a.player.name, slot: a.player.slot})) ?? [];
    const isDuplicate = (a: { action: Action, player: PlayerWithOptionalId }, folds: Fold[]) =>
        a.action.type === 'fold'
        && folds.filter(f => f.name == a.player.name && f.slot === a.player.slot).length > 0;
    const preFlopFolds = folds(stages.preFlop);
    stages.flop.actions = stages.flop.actions.filter(a => !isDuplicate(a, preFlopFolds));
    if (!stages.turn) {
        return stages;
    }
    const flopFolds = folds(stages.flop);
    stages.turn.actions = stages.turn.actions.filter(a => !isDuplicate(a, flopFolds) && !isDuplicate(a, preFlopFolds));
    if (!stages.river) {
        return stages;
    }
    const turnFolds = folds(stages.turn);
    stages.river.actions = stages.river.actions
        .filter(a => !isDuplicate(a, turnFolds) && !isDuplicate(a, flopFolds) && !isDuplicate(a, preFlopFolds));
    return stages;
}

const mergePlayers = (oldPlayers: PlayerWithOptionalId[], newPlayers: PlayerWithOptionalId[]): PlayerWithOptionalId[] => {
    const mergedPlayers = [...oldPlayers];
    newPlayers.forEach(player => {
        const existed = mergedPlayers.find(p => p.name === player.name && p.slot === player.slot);
        if (existed) {
            if (player.cards) {
                existed.cards = player.cards;
            }
            if (player.handName) {
                existed.handName = player.handName;
            }
            if (!existed.inFold) {
                existed.inFold = player.inFold;
            }
            return;
        }
        mergedPlayers.push(player);
    });
    return mergedPlayers;
}
export const mergeState = (oldState: GameState | undefined, newState: GameState): GameState | undefined => {
    if (!oldState) {
        return newState.stages.preFlop ? newState : undefined;
    }

    if (oldState.tableId !== newState.tableId || oldState.handId !== newState.handId) {
        return newState;
    }
    mergerLog(`[${new Date().toISOString()}] ${newState.handId} merger (old/new/merged):`);
    mergerLog(oldState);
    mergerLog(newState);
    const players = mergePlayers(oldState.players, newState.players);
    const stages = mergeStages(oldState.stages, newState.stages);
    setPlayerPositionsInActions(stages, players);
    mergerLog(stages);
    const mergedState = {
        tableId: newState.tableId,
        handId: newState.handId,
        limit: newState.limit,
        players,
        stages,
        result: newState.result
    };
    const {date, ...pureOldState} = oldState;
    if (_.isEqual(pureOldState, mergedState) || (pureOldState.result && !mergedState.result)) {
        return oldState;
    }
    return {...mergedState, date: newState.date}
}

const setPlayerPositionsInActions = (gameStage: GameStages, players: PlayerWithOptionalId[]) => {
    const actions = [
        ...gameStage.preFlop?.actions ?? [],
        ...gameStage.flop?.actions ?? [],
        ...gameStage.turn?.actions ?? [],
        ...gameStage.river?.actions ?? []
    ];
    actions.forEach(action => {
        const player = players.find(pl => pl.name === action.player.name && pl.slot === action.player.slot);
        action.player.position = player?.position ?? null;
    })
}