import React from 'react';
import css from './style.module.scss';
import Image from 'react-bootstrap/Image';
import { Carousel } from 'react-bootstrap';
import { Authenticator } from './Authenticator';

export const WelcomePage: React.FC = () => {
    return (
        <>
            <div className={css.centeredContainer}>
                <Image src="assets/pike-logo.svg" fluid width="15%"/>
                <h1>Welcome to Lazy Pike</h1>
                <p>Unofficial HUD for Tonpoker</p>
                <Authenticator/>
            </div>
            <Carousel style={{marginTop: '2em'}}>
                <Carousel.Item>
                    <div className={css.centeredContainer}>
                        <img src="/assets/games-tab-screen.png" alt='slide' style={{width: '70vw', opacity: 0.6}}/>
                    </div>
                    <Carousel.Caption>
                        <h3>Save your games</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <div className={css.centeredContainer}>
                        <img src="/assets/profiles-tab-screen.png" alt='slide' style={{width: '70vw', opacity: 0.6}}/>
                    </div>
                    <Carousel.Caption>
                        <h3>Collect stats of your opponents</h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    );
}