import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GameState, Parent, PlayerEntity } from '../service/types';
import { parseDomState } from '../service/parser';
import { mergeState } from '../service/merger';
import { gameStateToEntity } from '../service/mapper';
import { useReloading } from '../hook/useReloading';
import { useDb } from '../hook/useDb';
import { useExternalPageObserver } from '../hook/useExternalPageObserver';
import { useAuthenticationCheck } from '../hook/useAuthenticationCheck';
import { ApplicationContext } from '../service/context';
import { useRoomState } from '../hook/useRoomState';
import { TableDataAggregator } from '../service/adapter';
import { log } from '../service/logger';

export const AppContextProvider: React.FC<Parent> = ({children}) => {
    useAuthenticationCheck();
    const [error, setError] = useState('');
    const externalPage = useExternalPageObserver();
    const [game, setGame] = useState<GameState>();
    const [cachedGames, setCachedGames] = useState<GameState[]>([]);
    const [gamesCount, setGamesCount] = useState(0);
    const roomState = useRoomState();
    const forceReload = useMemo(() => roomState.roomId.length === 0, [roomState]);
    const {reloading, reloadProducer} = useReloading();
    const db = useDb();
    const refreshGamesCount = useCallback(() => {
        if (!db) {
            return;
        }
        db.getGamesCount().then(count => setGamesCount(count));
    }, [db]);

    useEffect(() => {
        refreshGamesCount();
    }, [db]);

    useEffect(() => {
        const aggregator = new TableDataAggregator(undefined, roomState);
        const tableId = aggregator.getTableId();
        if (db && tableId) {
            const cachedGamesOnTable = cachedGames.filter(g => g.tableId === tableId);
            console.log(`saving table ${tableId} data to db, cache: ${cachedGamesOnTable.length}`);
            if (cachedGamesOnTable.length === 0) {
                return;
            }
            const validGameStates = cachedGamesOnTable.filter(game => {
                const playersWithoutId = game.players.filter(p => !p.id).map(p => {
                    p.id = aggregator.getPlayerIdByName(p.name);
                    if (p.id) {
                        console.log(`Found player: ${p.name}`);
                    }
                    return p;
                }).filter(p => !p.id);
                return playersWithoutId.length === 0;
            });
            validGameStates.forEach(g => {
                console.log(`Saving of game from cache: ${g.tableId}/${g.handId}`);
                try {
                    const entity = gameStateToEntity(g);
                    db.saveGame(entity).then(() => refreshGamesCount());
                } catch (e) {
                    console.log('Failed to save');
                    log(e);
                }
            })
            const otherGames = cachedGames.filter(g => g.tableId !== tableId);
            const gamesOnTable = cachedGamesOnTable.filter(g => g.players.filter(p => !p.id).length > 0);
            setCachedGames([...otherGames, ...gamesOnTable]);
        }
    }, [db, roomState]);


    useEffect(() => {
        const aggregator = new TableDataAggregator(undefined, roomState);
        if (externalPage.html.length === 0 || reloading.tabStatus === 'reloading') {
            return;
        }
        console.log('Parsing...');
        const dom = new DOMParser().parseFromString(externalPage.html, 'text/html');
        try {
            const gameState = parseDomState(dom, aggregator);
            setGame(prevState => {
                const merged = mergeState(prevState, gameState);
                if (!merged || merged.players.length === 0 || merged.players.length > 6) {
                    return;
                }
                if (merged.players.filter(p => p.position === 'BB').length !== 1) {
                    log('Failed to find BB');
                    return;
                }
                setError('');
                return merged;
            });
        } catch (e) {
            const code = (e as Error).message;
            switch (code) {
                case 'SAME_NAME':
                    setError("There are two players with the same name on the current table, we cannot process such table, please, sit on another table");
                    break;
                case 'MANY_SAME_NAMES':
                    setError("There were two players with the same name on the current table, we cannot process such table, please, sit on another table");
                    break;
                case 'NO_TABLE_WRAPPER':
                    log(code);
                    return;
                case 'NO_BU':
                    log(code);
                    return;
            }
            console.log('Failed to parse state');
            log(e);
        }
    }, [externalPage.html, setGame, reloading.tabStatus, roomState]);

    useEffect(() => {
        if (!game || !game.result || !db || reloading.tabStatus === 'reloading') {
            return;
        }
        reloadProducer(forceReload);
        if (game.players.filter(p => !p.id).length > 0) {
            console.log(`Caching of game: ${game.tableId}/${game.handId}, missing players:`);
            console.log(game.players.filter(p => !p.id).map(p => p.name));
            setCachedGames(prevState => {
                const existing = prevState.find(g => g.tableId === game.tableId && g.handId === game.handId);
                if (existing) {
                    existing.players = game.players;
                    existing.stages = game.stages;
                    existing.result = game.result;
                    return prevState;
                }
                return [...prevState, game];
            });
            return;
        }
        console.log(`Saving of previous game: ${game.tableId}/${game.handId}`);
        try {
            const entity = gameStateToEntity(game);
            db.saveGame(entity).then(() => refreshGamesCount());
        } catch (e) {
            console.log(e);
        }
    }, [db, game]); // without reload related
    const players = useMemo(() => game?.players.filter(p => !!p.id).map(p => p as PlayerEntity) ?? [], [game]);
    if (db) {
        return <ApplicationContext.Provider value={{
            db,
            gamesCount,
            players,
            error
        }}>{children}</ApplicationContext.Provider>;
    }
    return <span>Initializing...</span>
}

export default AppContextProvider;
