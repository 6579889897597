import { GameTableState, RoomData } from './types';


export class TableDataAggregator {
    private readonly tableState: GameTableState | undefined;
    private readonly roomData: RoomData

    constructor(tableState: GameTableState | undefined, roomData: RoomData) {
        this.tableState = tableState;
        this.roomData = roomData;
    }

    getTableId() {
        if (this.tableState) {
            return this.tableState.id;
        }
        if (this.roomData.roomId.length !== 0) {
            return this.roomData.roomId;
        }
        return null;
    }

    getPlayerIdByName(name: string) {
        if ((this.tableState?.resultsOfPlayers.filter(pr => pr.username === name).length ?? 0) > 1) {
            throw new Error("MANY_SAME_NAMES");
        }
        return this.tableState?.resultsOfPlayers
            .find(pr => pr.username === name)?.userId ?? this.roomData.getPlayerId(name);
    }

    getLimit() {
        const rake = this.tableState?.config.RAKE_PERCENT;
        const sb = this.tableState?.config.sb;
        const bb = this.tableState?.config.bb;
        return this.tableState ? {rake, sb, bb} : this.roomData.limit;
    }

}