import React from 'react';
import { Form, Stack } from 'react-bootstrap';

interface PaginationSelectorProps {
    pagination: number;
    setPagination: (p: number) => void;
}

export const PaginationSelector: React.FC<PaginationSelectorProps> = ({pagination, setPagination}) => {
    return (
        <Form style={{color: 'whitesmoke'}}>
            <Stack direction='horizontal' gap={2} style={{height: '4em', marginLeft: '0.2em'}}>
                <Form.Label column style={{maxWidth: 'fit-content', fontWeight: 'bold'}}>How many to show:</Form.Label>
                <Form.Select
                    onChange={event => setPagination(+event.target.value)}
                    style={{width: 'fit-content', color: 'whitesmoke', padding: '0.25em 0.5em'}}
                    value={pagination}
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={40}>40</option>
                    <option value={80}>80</option>
                    <option value={0}>All</option>
                </Form.Select>
            </Stack>
        </Form>
    );
}