import _ from 'lodash';

class CircleNode<T> {
    public value: T;
    public next: CircleNode<T>;

    constructor(value: T) {
        this.value = value;
        this.next = this;
    }
}

export class Circle<T> {
    private head: CircleNode<T> | null = null;

    public setHead(newHead: CircleNode<T>): Circle<T> {
        this.head = newHead;
        return this;
    }

    public getHead(): CircleNode<T> | null {
        return this.head;
    }

    public add(value: T): CircleNode<T> {
        const node = new CircleNode(value);
        if (!this.head) {
            this.head = node;
            return node;
        }
        const getLast = (node: CircleNode<T>): CircleNode<T> => {
            return _.isEqual(node.next, this.head) ? node : getLast(node.next);
        };
        const lastNode = getLast(this.head);
        lastNode.next = node;
        node.next = this.head;
        return node;
    }

    public find(comparator: (value: T) => boolean): CircleNode<T> | null {
        const checkNext = (node: CircleNode<T>): CircleNode<T> | null => {
            if (comparator(node.value)) {
                return node;
            }
            return _.isEqual(node.next, this.head) ? null : checkNext(node.next);
        };

        return this.head ? checkNext(this.head) : null;
    }

    public addArray(array: T[]): Circle<T> {
        array.forEach(i => this.add(i));
        return this;
    }

    public toArray(): T[] {
        const array: T[] = [];
        if (!this.head) {
            return array;
        }

        const addToArray = (node: CircleNode<T>): T[] => {
            array.push(node.value);
            return _.isEqual(node.next, this.head) ? array : addToArray(node.next);
        };
        return addToArray(this.head);
    }

    public size(): number {
        return this.toArray().length;
    }
}