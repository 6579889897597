import { GameEntity } from './types';

export interface PlayerResult {
    userId: string;
    tableId: string;
    username: string;
    result: number;
}

export class Db {
    public static stores = {
        games: 'games',
    }
    public static indexes = {
        gameUnique: 'gameUnique',
        playerIds: 'playerIds',
        date: 'date',
    }
    private db: IDBDatabase;

    constructor(db: IDBDatabase) {
        this.db = db;
    }

    public saveGame(entity: GameEntity): Promise<string[]> {
        return new Promise((resolve, reject) => {
            const store = this.db.transaction(Db.stores.games, 'readwrite').objectStore(Db.stores.games);
            const request = store.put(entity);
            request.onsuccess = (event) => {
                const result = (event.target as any).result as string[];
                resolve(result);
            }
            request.onerror = () => {
                console.log(`Failed to save game: handId = ${entity.handId}, tableId = ${entity.tableId}`);
                reject();
            }
        });
    }

    public getGame(tableId: string, handId: string): Promise<GameEntity> {
        return new Promise((resolve, reject) => {
            const store = this.db.transaction(Db.stores.games).objectStore(Db.stores.games);
            const request = store.get([tableId, handId]);
            request.onsuccess = (event) => {
                const result = (event.target as any).result as GameEntity;
                resolve(result);
            }
            request.onerror = () => {
                console.log(`Failed to get game: handId = ${handId}, tableId = ${tableId}`);
                reject();
            }
        });
    }

    public getAllGames(): Promise<GameEntity[]> {
        return new Promise((resolve, reject) => {
            const store = this.db.transaction(Db.stores.games).objectStore(Db.stores.games);
            const request = store.getAll();
            request.onsuccess = (event) => {
                const result = (event.target as any).result as GameEntity[];
                resolve(result);
            }
            request.onerror = () => {
                console.log('Failed to get games');
                reject();
            }
        });
    }

    public getLastGames(count: number): Promise<GameEntity[]> {
        return new Promise((resolve, reject) => {
            const store = this.db.transaction(Db.stores.games).objectStore(Db.stores.games);
            const request = store.index(Db.indexes.date).openCursor(null,'prev');
            let counter = 1;
            const games: GameEntity[] = [];
            request.onsuccess = (event) => {
                const cursor = (event.target as any).result;
                if (cursor) {
                    const value = cursor.value;
                    counter++;
                    games.push(value)
                    if (counter < count || count === 0) {
                        cursor.continue();
                    } else {
                        resolve(games);
                    }
                } else {
                    resolve(games);
                }
            }
            request.onerror = () => {
                console.log('Failed to get games');
                reject();
            }
        });
    }

    public getGamesCount(): Promise<number> {
        return new Promise((resolve, reject) => {
            const store = this.db.transaction(Db.stores.games).objectStore(Db.stores.games);
            const request = store.count();
            request.onsuccess = (event) => {
                const result = (event.target as any).result as number;
                resolve(result);
            }
            request.onerror = () => {
                console.log('Failed to get games count');
                reject();
            }
        });
    }

    public getGamesByPlayer(playerId: string): Promise<GameEntity[]> {
        return new Promise((resolve, reject) => {
            const store = this.db.transaction(Db.stores.games).objectStore(Db.stores.games);
            const index = store.index(Db.indexes.playerIds);
            const range = IDBKeyRange.only(playerId);
            const request = index.openCursor(range);
            const games: GameEntity[] = [];
            request.onsuccess = (event) => {
                const cursor = (event.target as any).result as IDBCursorWithValue;
                if (cursor) {
                    games.push(cursor.value);
                    cursor.continue();
                    return;
                }
                resolve(games);
            }
            request.onerror = () => {
                console.log(`Failed to get games by player: ${playerId}`);
                reject();
            }
        })
    }
}

export const initDb = (): Promise<Db> => {
    return new Promise((resolve, reject) => {
        const openRequest = window.indexedDB.open("db", 1);
        openRequest.onerror = () => {
            console.error("Failed to create indexed db");
            reject();
        };
        openRequest.onupgradeneeded = (event) => {
            const db = (event.target as any).result as IDBDatabase;
            const gameStore = db.createObjectStore(Db.stores.games, {keyPath: ["tableId", "handId"]});
            gameStore.createIndex(Db.indexes.gameUnique, ["tableId", "handId"], {unique: true});
            gameStore.createIndex(Db.indexes.playerIds, "playerIds", {unique: false, multiEntry: true});
            gameStore.createIndex(Db.indexes.date, "date");
        };
        openRequest.onsuccess = (event) => {
            const db = (event.target as any).result as IDBDatabase;
            resolve(new Db(db));
        }
    });
}