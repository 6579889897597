import { GameEntity, GameState, PlayerWithOptionalId, Stage } from './types';

const stageBetSum = (stage: Stage | undefined, players: PlayerWithOptionalId[]): number => {
    if (!stage) {
        return 0;
    }
    let sum = 0;
    players.forEach(player => {
        const actions = stage?.actions ?? [];
        const bet = actions.filter(a => a.action.type === 'bet'
            && a.player.name === player.name
            && a.player.slot === player.slot)
            .map(a => a.action.amount)
            .reduce((a, b) => a < b ? b : a, 0) ?? 0;
        sum = sum + bet;
    })
    return sum;
}
export const gameStateToEntity = (state: GameState): GameEntity => {
    const {stages, players} = state;
    if (stages.flop && stages.preFlop) {
        const sumPreFlop = stageBetSum(stages.preFlop, players) + stages.preFlop.pot;
        if (Math.round(sumPreFlop) !== Math.round(stages.flop.pot)) {
            console.log(`Sum: ${sumPreFlop}`);
            console.log('State from mapper:');
            console.log(state);
            throw new Error(`PreFlop Pot validation failed: ${state.tableId}/${state.handId}`);
        }
    }
    if (stages.turn && stages.flop) {
        const sumFlop = stageBetSum(stages.flop, players) + stages.flop.pot;
        if (Math.round(sumFlop) !== Math.round(stages.turn.pot)) {
            console.log(`Sum: ${sumFlop}`);
            console.log('State from mapper:');
            console.log(state);
            throw new Error(`Flop Pot validation failed: ${state.tableId}/${state.handId}`);
        }
    }
    if (stages.river && stages.turn) {
        const sumTurn = stageBetSum(stages.turn, players) + stages.turn.pot;
        if (Math.round(sumTurn) !== Math.round(stages.river.pot)) {
            console.log(`Sum: ${sumTurn}`);
            console.log('State from mapper:');
            console.log(state);
            throw new Error(`Turn Pot validation failed: ${state.tableId}/${state.handId}`);
        }
    }

    const unknownPlayers = state.players.filter(p => !p.id).map(p => p.name);
    if (unknownPlayers.length > 0) {
        throw new Error(`Failed to find ids for players: ${unknownPlayers}`);
    }
    const gameEntity = state as Omit<GameEntity, 'playerIds'>;
    return {
        playerIds: gameEntity.players.map(p => p.id),
        ...gameEntity,
    };
}