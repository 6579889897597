import { useEffect, useState } from 'react';
import { ContentMessage } from '../service/types';

interface ExternalPageState {
    url: string;
    html: string;
}

const waitingMutation = '<div class="_line';
export const useExternalPageObserver = () => {
    const [externalPage, setExternalPage] = useState<ExternalPageState>({url: '', html: ''});
    useEffect(() => {
        window.addEventListener('mutation', event => {
            const msg = (event as any).detail as ContentMessage;
            if (msg.target && msg.target.startsWith(waitingMutation)) {
                return;
            }
            setExternalPage({
                url: msg.url,
                html: msg.root
            });
        }, false)
    }, [setExternalPage]);
    return externalPage;
}