export const log = (data: any) => {
    if(window.localStorage.getItem('audit') === '1'){
        console.log(`[${new Date().toISOString()}] audit:`);
        console.log(data);
    }
}

export const mergerLog = (data: any) => {
    if(window.localStorage.getItem('audit-mr') === '1'){
        console.log(data);
    }
}

export const wsLog = (data: any) => {
    if (window.localStorage.getItem('audit-ws') === '1') {
        console.log(data);
    }
}