import React from 'react';
import { Action, GameEntity, PlayerEntity, StageEntity } from '../service/types';
import { StageHeader } from './StageHeader';
import { CardBadge } from './CardBadge';
import { Stack } from 'react-bootstrap';
import { PreFlopHeader } from './PreFlopHeader';


const actionView = (action: React.ReactNode, player: PlayerEntity, outOfGame: boolean) => {
    const style: React.CSSProperties = {whiteSpace: 'nowrap'};
    if (outOfGame) {
        style.opacity = 0.5;
    }
    return <div style={style}>
        <b>{player.name} ({player.position}): </b>
        {action}
    </div>
}

const actionToNode = (action: Action): React.ReactNode => {
    return <span>{action.subType ?? action.type} {action.amount > 0 ? action.amount : ''}</span>
}

const stageAction = (actionData: { action: Action, player: PlayerEntity }) =>
    actionView(actionToNode(actionData.action), actionData.player, false);


const riverActionsWithShowdown = (game: GameEntity) => {
    const {river} = game.stages;
    if (!river || river.actions.length === 0) {
        return [];
    }
    const actions = getSortedActions(river);
    const fullActions = actions.filter(a => a.action.type !== 'won').map(a => ({
        action: actionToNode(a.action),
        player: a.player,
        outOfGame: false,
    }));
    game.players.forEach(p => {
        if (p.handName && p.cards) {
            fullActions.push({
                action: <Stack direction="horizontal" gap={1}>
                    <CardBadge card={p.cards.first}/>
                    <CardBadge card={p.cards.second}/>
                    <span>{p.handName}</span>
                </Stack>,
                player: p,
                outOfGame: p.inFold
            });
        }
    });
    actions.filter(a => a.action.type === 'won').forEach(a => fullActions.push({
        action: actionToNode(a.action),
        player: a.player,
        outOfGame: false,
    }))
    return fullActions;
}
const getSortedActions = (stage?: StageEntity) => stage?.actions.sort((a,b) => a.order - b.order) ?? [];
export const GameRow: React.FC<{ game: GameEntity }> = ({game}) => {
    const {preFlop, flop, turn, river} = game.stages;
    const riverActions = riverActionsWithShowdown(game);
    const maxActionCount = Math.max(
        preFlop?.actions.length ?? 0,
        flop?.actions.length ?? 0,
        turn?.actions.length ?? 0,
        riverActions.length ?? 0,
    ) ?? 0;
    const rows = Array.from(Array(maxActionCount).keys());
    const playersInGame = game.players.filter(p => p.inGame).length;
    return (<>
            <tr>
                <td rowSpan={maxActionCount + 1} style={{ width: '6em'}}>
                    <div>{game.tableId}</div>
                    <div>{game.handId}</div>
                    <div>{game.date.toLocaleString()}</div>
                </td>
                <td><PreFlopHeader playerCount={playersInGame} {...game.limit}/></td>
                <td><StageHeader stage={flop} cards={flop?.cards ?? []}/></td>
                <td><StageHeader stage={turn} cards={turn?.card ? [turn?.card] : []}/></td>
                <td><StageHeader stage={river} cards={river?.card ? [river?.card] : []}/></td>
            </tr>
            {rows.map(rowNumber => {
                const preFlopAction = getSortedActions(preFlop)[rowNumber];
                const flopAction = getSortedActions(flop)[rowNumber];
                const turnAction = getSortedActions(turn)[rowNumber];
                const riverAction = riverActions[rowNumber];
                return (
                    <tr>
                        {preFlopAction ? <td>{stageAction(preFlopAction)}</td> : <td/>}
                        {flopAction ? <td>{stageAction(flopAction)}</td> : <td/>}
                        {turnAction ? <td>{stageAction(turnAction)}</td> : <td/>}
                        {riverAction ?
                            <td>{actionView(riverAction.action, riverAction.player, riverAction.outOfGame)}</td> :
                            <td/>}
                    </tr>
                )
            })}
        </>
    );
}