import {
    Action,
    Card,
    CardSuit,
    CardValue,
    GameStages,
    GameState,
    PlayerWithOptionalId,
    Position,
    positionsForXMax,
    Stage,
    Winner
} from './types';
import { Circle } from './circle';
import { TableDataAggregator } from './adapter';

const parseTableNumber = (dom: Document): string => {
    const tableDiv = dom.querySelector('[class^="_table_id_"]');
    if (tableDiv === null || tableDiv.textContent === null) {
        throw new Error("Failed to find Table Id");
    }
    return tableDiv.textContent;
}

const parseCardValue = (str: string): CardValue | undefined => {
    return Object.values(CardValue).find(i => i.toString() === str);
}
const parseCardSuit = (str: string): CardSuit | undefined => {
    return Object.values(CardSuit).find(i => i.toString() === str);
}
const parseDeck = (dom: Document): Card[] => {
    const cards: Card[] = [];
    const deckContainer = dom.querySelector('div[class^="_cards_container_"]');
    if (!deckContainer) {
        return cards;
    }
    deckContainer.querySelectorAll('div[data-role="card-layout"]').forEach(cardDiv => {
        const card = parseCard(cardDiv);
        if (card !== null) {
            cards.push(card);
        }
    });
    return cards;
}

const parseCard = (cardDiv: Element | undefined | null): Card | null => {
    if (!cardDiv) {
        return null;
    }
    const dataValue = cardDiv.getAttribute("data-value");
    const dataSuit = cardDiv.getAttribute("data-suit");
    if (dataValue && dataSuit) {
        const value = parseCardValue(dataValue);
        const suit = parseCardSuit(dataSuit);
        if (value && suit) {
            return {
                value,
                suit
            }
        }
    }
    return null;
}

const parsePlayer = (dom: Document, seatNum: number): PlayerWithOptionalId | null => {
    const slot = dom.querySelector(`div[data-slot-index="${seatNum}"][data-type="sit"]`);
    const name = slot?.querySelector('[class^="_name_"]')?.textContent;
    const stack = slot?.querySelector('[class^="_money_"]')?.textContent;
    const dealerChip = slot?.querySelector('div[data-type="chip"][data-chip="dealer"]');
    const firstCardDiv = slot?.querySelector('div[data-type="card"][data-card-index="0"]');
    const secondCardDiv = slot?.querySelector('div[data-type="card"][data-card-index="1"]');
    const handName = slot?.querySelector('div[data-type="hand-name"]')?.textContent;
    const firstCard = parseCard(firstCardDiv?.querySelector('div[data-role="card-layout"]'));
    const secondCard = parseCard(secondCardDiv?.querySelector('div[data-role="card-layout"]'));
    const hasFoldAvatar = !!(slot?.querySelector('div[data-type="fold"]'));
    if (!name || !stack) {
        return null;
    }
    const cards = firstCard && secondCard ? {first: firstCard, second: secondCard} : undefined;
    return {
        name,
        stack: +stack,
        slot: seatNum,
        position: dealerChip ? Position.BU : null,
        inGame: !!(firstCardDiv && secondCardDiv),
        inFold: hasFoldAvatar,
        cards: cards,
        handName: handName ?? undefined,
    }
}

const parsePlayers = (dom: Document, tableDataAggregator: TableDataAggregator, tableId: string, withPosition: boolean = false): PlayerWithOptionalId[] => {
    let players: PlayerWithOptionalId[] = [];
    Array.from(Array(6).keys()).forEach(i => {
        const player = parsePlayer(dom, i);
        if (player !== null) {
            if (players.map(p => p.name).indexOf(player.name) > 0) {
                throw new Error('SAME_NAME');
            }
            players.push(player);
        }
    });

    if (withPosition) {
        const positions = positionsForXMax(players.length);
        const positionsCircle = new Circle<Position>().addArray(positions);
        const playerCircle = new Circle<PlayerWithOptionalId>().addArray(players);
        const buttonPosition = positionsCircle.find(p => p === Position.BU);
        const buttonPlayer = playerCircle.find(p => p.position === Position.BU);
        if (!buttonPlayer || !buttonPosition) {
            throw new Error(`NO_BU`);
        }
        const posArray = positionsCircle.setHead(buttonPosition).toArray();
        const playerArray = playerCircle.setHead(buttonPlayer).toArray();
        const withPosition = playerArray.map((value, index) => {
            value.position = posArray[index];
            return value;
        })
        const tableCircle = new Circle<PlayerWithOptionalId>().addArray(withPosition);
        const blind = tableCircle.getHead()?.next;
        if (blind) {
            tableCircle.setHead(blind);
        }
        players = tableCircle.toArray();
    }
    return addPlayerIds(players, tableDataAggregator, tableId);
}

const addPlayerIds = (players: PlayerWithOptionalId[], aggregator: TableDataAggregator, tableId: string) => {
    return players.map((value) => {
        const id = tableId === aggregator.getTableId()
            ? aggregator.getPlayerIdByName(value.name)
            : undefined;
        return {id: id, ...value};
    })
}

const parseStageActions = (dom: Document, players: PlayerWithOptionalId[], blinds?: boolean): Stage => {
    const pot = dom?.querySelector('[class^="_pot_"]')?.textContent ?? '0';
    const actions: {
        player: PlayerWithOptionalId;
        action: Action;
    }[] = [];
    players.forEach(player => {
        const slot = dom.querySelector(`div[data-slot-index="${player.slot}"][data-type="chip-container"]`);
        const dataChipDivList = slot?.querySelectorAll('div[data-type="chip"]:not([data-chip="dealer"])');
        const dataChipDivs: Element[] = [];
        dataChipDivList?.forEach(div => {
            dataChipDivs.push(div);
        });
        const checkDiv = dataChipDivs.find(el => el.getAttribute("data-chip") === "check");
        const betDiv = dataChipDivs.find(el => el.getAttribute("data-chip") === "bet");
        const wonDiv = dataChipDivs.find(el => el.getAttribute("data-chip") === "won");
        if (checkDiv) {
            actions.push({
                player,
                action: {
                    type: 'check',
                    amount: 0
                }
            });
        }
        if (betDiv) {
            const bet = +(betDiv.textContent ?? '0');
            const onBb = player.position && player.position === Position.BB;
            const subType = blinds && ((onBb && bet === 1) || bet < 1) ? 'blind' : undefined;
            actions.push({
                player,
                action: {
                    type: 'bet',
                    amount: bet,
                    subType,
                }
            });
        }

        if (wonDiv) {
            const won = wonDiv?.textContent ?? '0';
            const bbIndex = won.indexOf("BB");
            // 1 for "+"
            const wonNum = bbIndex > 0 ? won.substring(1, bbIndex).trim() : won;
            actions.push({
                player,
                action: {
                    type: 'won',
                    amount: +wonNum
                }
            });
        }

        if (player.inFold) {
            actions.push({
                player,
                action: {
                    type: 'fold',
                    amount: 0
                },
            });
        }
    });
    const bbIndex = pot.indexOf("BB");
    const potNum = bbIndex > 0 ? pot.substring(0, bbIndex).trim() : pot;
    const actionTypeOrder = (act: Action) => {
        switch (act.type) {
            case 'bet':
                return 2;
            case 'check':
                return 2;
            case 'fold':
                return 1;
            case 'won':
                return 0;
        }
    }
    const sortedActions = actions.sort((a, b) => actionTypeOrder(a.action) - actionTypeOrder(b.action));
    return {actions: sortedActions, pot: +potNum};
}
const parseStages = (dom: Document, deck: Card[], players: PlayerWithOptionalId[]): GameStages => {
    if (deck.length === 0) {
        return {
            preFlop: parseStageActions(dom, players, true)
        }
    }

    if (deck.length === 3) {
        return {
            flop: {
                cards: deck,
                ...parseStageActions(dom, players)
            }
        }
    }

    if (deck.length === 4) {
        return {
            turn: {
                card: deck[3],
                ...parseStageActions(dom, players)
            }
        }
    }

    if (deck.length === 5) {
        return {
            river: {
                card: deck[4],
                ...parseStageActions(dom, players)
            }
        }
    }
    return {}
}
const calculateWinners = (stages: GameStages): Winner[] => {
    const allActions = [
        ...(stages.preFlop?.actions ?? []),
        ...(stages.flop?.actions ?? []),
        ...(stages.turn?.actions ?? []),
        ...(stages.river?.actions ?? []),
    ];
    const winnings = allActions.filter(act => act.action.type === 'won');
    if (winnings.length > 0) {
        return winnings.map(winning => {
            return {
                player: winning.player,
                amount: winning.action.amount,
                combo: winning.player.handName,
            }
        })
    }
    return [];
}
export const parseDomState = (dom: Document, tableDataAggregator: TableDataAggregator): GameState => {
    const table = dom.querySelector('div[data-role="game-table-wrap"]');
    if (!table) {
        throw new Error("NO_TABLE_WRAPPER");
    }
    const tableNumberParts = parseTableNumber(dom).split('/#');
    const deckCards = parseDeck(dom);
    const preFlop = deckCards.length === 0;
    const players = parsePlayers(dom, tableDataAggregator, tableNumberParts[0], preFlop);
    const stages = parseStages(dom, deckCards, players);
    const winners = calculateWinners(stages);
    const limit = tableDataAggregator.getLimit();
    return {
        tableId: tableNumberParts[0],
        handId: tableNumberParts[1],
        date: new Date(),
        limit: {
            sb: limit?.sb ?? 0,
            bb: limit?.bb ?? 0,
            rake: limit?.rake ?? 0
        },
        players: players.filter(p => p.inGame || p.inFold),
        stages,
        result: winners.length === 0 ? undefined : {
            won: winners.length === 1 ? winners[0] : undefined,
            split: winners.length > 1 ? winners : undefined
        }
    };
}


