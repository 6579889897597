import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { WelcomePage } from './component/WelcomePage';
import { ErrorPage } from './component/RouteError';
import { UserContextProvider } from './component/UserContextProvider';
import { AppContainer } from './component/AppContainer';
import { useConnectionHandler } from './hook/useConnectionHandler';
import { ConnectionContext } from './service/context';
import { Guide } from './component/Guide';
import { Navigation } from './component/Navigation';


export const Root: React.FC = () => {
    const connection = useConnectionHandler();
    return (
        <BrowserRouter>
            <Routes>
                <Route element={
                    <ConnectionContext.Provider value={connection}>
                        <UserContextProvider>
                            <Outlet/>
                        </UserContextProvider>
                    </ConnectionContext.Provider>
                }
                       errorElement={<ErrorPage/>}
                >
                    <Route path="/" element={<WelcomePage/>}/>
                    <Route path="/app" element={
                        <AppContainer>
                            <Outlet/>
                        </AppContainer>
                    }>
                        <Route path="/app/guide" element={<Guide/>}/>
                        <Route path="/app" element={<Navigation/>}/>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}