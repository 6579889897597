import React, { useContext, useRef } from 'react';
import { Alert, Image, Nav, Navbar, Overlay, Stack } from 'react-bootstrap';
import css from './style.module.scss';
import { useNavigate } from 'react-router-dom';
import { GlobalControls } from './GlobalControls';
import { ApplicationContext, ConnectionContext, UserContext } from '../service/context';
import AppContextProvider from './AppContextProvider';
import { Parent } from '../service/types';

const bgColor = '#212529';
export const AppContainer: React.FC<Parent> = ({children}) => {
    const navigate = useNavigate();
    const connected = useContext(ConnectionContext);
    const {user} = useContext(UserContext);
    const appState = useContext(ApplicationContext);
    const ref = useRef(null);
    const error = appState?.error ?? '';
    return (
        <Stack direction='vertical' style={{height: '100vh', background: '#212529'}}>
            <Navbar data-bs-theme="dark" style={{
                padding: '0 1em 0 1em',
                background: bgColor
            }}>
                <div style={{width: '100px'}}>
                    <GlobalControls connected={connected}/>
                </div>
                <div className={css.centeredContainer} ref={ref}>
                    <Image
                        src="assets/pike-logo-white.png"
                        fluid
                        onClick={() => navigate("/app")}
                        style={{cursor: 'pointer', width: '40px'}}
                        alt=''
                    />
                </div>
                <Overlay target={ref.current} show={error.length > 0} placement="bottom">
                    <Alert variant='danger'>{error}</Alert>
                </Overlay>
                <Navbar.Collapse className="justify-content-end" style={{minWidth: 'fit-content', width: '100px'}}>
                    <Image
                        src="/assets/help.svg"
                        onClick={() => navigate("/app/guide")}
                        style={{marginRight: '1em', cursor: 'help'}}
                    />
                    <Navbar.Text onClick={() => navigate("/")} style={{color: 'whitesmoke', cursor: 'pointer'}}>
                        {user?.name ?? 'Player'}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
            <AppContextProvider>
                {children}
            </AppContextProvider>
            <div
                className={css.centeredContainer}
                style={{backgroundColor: bgColor, height: '2em', marginTop: 'auto', borderTop: '1px solid grey'}}
            >
                <Nav.Link href='https://t.me/LazyPikeChannel'>Support in our telegram</Nav.Link>
            </div>
        </Stack>
    );
}