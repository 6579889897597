import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { Db } from '../service/db';
import { GameRow } from './GameRow';
import { GameEntity } from '../service/types';
import { PaginationSelector } from './PaginationSelector';
import css from './style.module.scss';

export const GameLog: React.FC<{ db: Db, count: number }> = ({db, count}) => {
    const [games, setGames] = useState<GameEntity[]>([]);
    const [pagination, setPagination] = useState(10);
    useEffect(() => {
        db?.getLastGames(pagination).then(games => setGames(games));
    }, [db, setGames, pagination, count])
    return (
        <div className={count > 0 ? css.centeredContainer : undefined}
             style={{overflowX: 'auto', background: '#333333', height: '100%'}}
        >
            <div style={{maxWidth: '100%'}}>
                <PaginationSelector pagination={pagination} setPagination={setPagination}/>
                <Table striped="columns" bordered variant="dark" responsive>
                    <thead>
                    <tr>
                        <th style={{width: '6em'}}>Game ID</th>
                        <th>Pre-flop</th>
                        <th>Flop</th>
                        <th>Turn</th>
                        <th>River</th>
                    </tr>
                    </thead>
                    <tbody>
                    {games.map(game => <GameRow game={game}/>)}
                    </tbody>
                </Table>
                {games.length === 0 &&
                    <div className={css.centeredContainer}>
                        <Form.Label column style={{maxWidth: 'fit-content', fontWeight: 'bold', color: 'whitesmoke'}}>
                            Games may take up to 5 minutes after connection to appear.
                        </Form.Label>
                    </div>
                }
            </div>
        </div>
    );
}