import React, { useContext } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { GameLog } from './GameLog';
import css from './style.module.scss';
import { PlayerProfiles } from './PlayerProfiles';
import { Badge, Stack } from 'react-bootstrap';
import { ApplicationContext } from '../service/context';

export const Navigation: React.FC = () => {
    const appState = useContext(ApplicationContext);
    if (!appState) {
        return <>Initializing...</>;
    }
    const {db, gamesCount, players} = appState;
    return (
        <Tabs
            defaultActiveKey="games"
            justify
            mountOnEnter
            unmountOnExit
            data-bs-theme="secondary"
            className={css.tabs}
        >
            <Tab eventKey="games" title={
                <div style={{ height: '2.9ch' }}>
                    <div className={css.centeredContainer}>
                        <div>
                            <Stack direction='horizontal' gap={1}>
                                <div style={{margin: '0', fontWeight: 'bold'}}>Games</div>
                                <Badge
                                    bg="light"
                                    text='black'
                                    style={{width: 'fit-content', fontSize: '0.6em', marginBottom: '1em'}}
                                ><b>{gamesCount}</b></Badge>
                            </Stack>
                        </div>
                    </div>
                </div>
            } disabled={!db}>
                <GameLog db={db} count={gamesCount}/>
            </Tab>
            <Tab
                eventKey="profiles"
                title={<div style={{fontWeight: 'bold', height: '2.9ch', color: 'whitesmoke'}}>Profiles</div>}
            >
                <PlayerProfiles db={db} players={players} count={gamesCount}/>
            </Tab>
            <Tab
                eventKey="suggestions"
                disabled
                title={<div style={{fontWeight: 'bold', color: 'grey'}}>Suggestions</div>}
            >
                <div className={css.centeredContainer} style={{marginTop: '5em'}}> Coming soon...</div>
            </Tab>
        </Tabs>
    );
}