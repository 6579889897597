import React from 'react';
import { Badge, Stack } from 'react-bootstrap';


type Props = {
    sb: number;
    bb: number;
    rake: number;
    playerCount: number;
};
export const PreFlopHeader: React.FC<Props> = ({sb, bb, rake, playerCount}) => {
    return (
        <Stack direction="vertical" gap={1} className='mx-auto'>
            <Stack direction="horizontal" gap={1}>
                <Badge bg="info" text="light" style={{width: 'fit-content'}}>NLH - {sb}/{bb}
                </Badge>
                <Badge bg="danger" text="light">-{rake}%</Badge>
            </Stack>
            <div>{playerCount} <img src="/assets/people-fill.svg" alt="Players"/></div>
        </Stack>
    );
}