import React from 'react';
import { Db } from './db';

export enum CardValue {
    two = '2',
    three = '3',
    four = '4',
    five = '5',
    six = '6',
    seven = '7',
    eight = '8',
    nine = '9',
    ten = 'T',
    jack = 'J',
    queen = 'Q',
    king = 'K',
    ace = 'A'
}

export enum CardSuit {
    diamonds = 'd',
    clubs = 'c',
    hearts = 'h',
    spades = 's',
}

export interface Card {
    value: CardValue;
    suit: CardSuit;
}

export const suitIcons: Record<CardSuit, string> = {
    [CardSuit.diamonds]: '♦',
    [CardSuit.clubs]: '♣',
    [CardSuit.hearts]: '♥',
    [CardSuit.spades]: '♠'
}

export const suitColors: Record<CardSuit, string> = {
    [CardSuit.diamonds]: 'primary',
    [CardSuit.clubs]: 'success',
    [CardSuit.hearts]: 'danger',
    [CardSuit.spades]: 'dark'
}

export type PlayerWithOptionalId = Omit<PlayerEntity, 'id'> & { id?: string };

export interface PlayerEntity {
    id: string;
    name: string;
    slot: number;
    position: Position | null;
    stack: number;
    cards?: {
        first: Card;
        second: Card;
    };
    handName?: string;
    inGame: boolean;
    inFold: boolean;
}

// export enum Position9Max {
//     UTG = 'UTG',
//     UTG_1 = 'UTG+1',
//     MP = 'MP',
//     MP_1 = 'MP+1',
//     HJ = 'HJ',
//     CO = 'CO',
//     BU = 'BU',
//     SB = 'SB',
//     BB = 'BB'
// }

export enum Position {
    UTG = 'UTG',
    MP = 'MP',
    CO = 'CO',
    BU = 'BU',
    SB = 'SB',
    BB = 'BB'
}

// const positions9Max = Object.values(Position9Max).filter(p => isNaN(+p)).map(p => p as Position9Max);
const positions = Object.values(Position).filter(p => isNaN(+p));

export const positionsForXMax = (max: number): Position[] => {
    if (max < 2 || max > 6) {
        throw new Error("Unknown amount of player");
    }
    if (max === 2) {
        return [Position.BU, Position.BB];
    }
    if (max <= 6) {
        return Array.from(Array(6).keys())
            .filter(i => i >= 6 - max)
            .map(i => positions[i]);
    }
    return [];
}


export interface Action {
    type: 'bet' | 'check' | 'won' | 'fold';
    amount: number;
    subType?: 'push' | 'call' | 'raise' | 'blind'
}

export interface Stage {
    actions: {
        player: PlayerWithOptionalId;
        action: Action;
        order?: number
    }[],
    pot: number;
}

export interface StageEntity {
    actions: {
        player: PlayerEntity;
        action: Action;
        order: number
    }[],
    pot: number;
}

export interface GameStages {
    preFlop?: Stage,
    flop?: Stage & {
        cards: Card[]
    },
    turn?: Stage & {
        card: Card,
    },
    river?: Stage & {
        card: Card
    }
}

export interface GameStagesEntity {
    preFlop?: StageEntity,
    flop?: StageEntity & {
        cards: Card[]
    },
    turn?: StageEntity & {
        card: Card,
    },
    river?: StageEntity & {
        card: Card
    }
}

export interface Winner {
    player: PlayerWithOptionalId;
    amount: number;
    combo?: string;
}

export interface WinnerEntity {
    player: PlayerEntity;
    amount: number;
    combo?: string;
}

export interface GameState {
    tableId: string;
    handId: string;
    date: Date;
    limit: {
        sb: number;
        bb: number;
        rake: number;
    }
    players: PlayerWithOptionalId[];
    stages: GameStages;
    result?: {
        won?: Winner;
        split?: Winner[];
    };
}

export interface GameEntity {
    tableId: string;
    handId: string;
    limit: {
        sb: number;
        bb: number;
        rake: number;
    },
    date: Date;
    players: PlayerEntity[];
    playerIds: string[];
    stages: GameStagesEntity;
    result?: {
        won?: WinnerEntity;
        split?: WinnerEntity[];
    };
}

export interface GameTableState {
    id: string;
    config: {
        sb: number;
        bb: number;
        RAKE_PERCENT: number;
        players: {
            id: string;
            seat: number;
            stack: number;
        }[]
    }
    resultsOfPlayers: {
        result: number;
        userId: string;
        username: string;
    }[]
}

export interface RoomState {
    sessionId: string;
    room: {
        clients: number,
        createdAt: string,
        name: string,
        processId: string,
        publicAddress: string,
        roomId: string
        metadata: {
            config: {
                sb: number;
                bb: number;
                RAKE_PERCENT: number;
                players: {
                    id: string;
                    seat: number;
                    stack: number;
                    username: string;
                }[],
            },
            tablePlayers: string[];
        }
    }
}

export interface Limit {
    sb: number;
    bb: number;
    rake: number;
}

export interface RoomData {
    getPlayerId: (name: string) => string | undefined;
    roomId: string,
    limit: Limit | undefined;
}

export interface ContentMessage {
    target: string;
    root: string;
    type: string;
    url: string;
}

export interface AsyncState<T> {
    status: 'inProgress' | 'ready' | 'empty' | 'error';
    data?: T
}

export interface PlayerProfile {
    id: string;
    name: string;
    totalGames: number;
    games: GameEntity[];
    preflop: {
        vpip: number;
        pfr: number;
        ats: number;
        threeBet: number;
    }
    flop: {
        cbet: number;
        fcb: number;
        ccb: number;
        rcb: number;
    }
    turn: {
        cbet: number;
        fcb: number;
        ccb: number;
        rcb: number;
    },
    river: {
        wt: number;
        wsd: number;
    }
}

export interface Parent {
    children?: React.ReactNode;
}

export interface AppContextState {
    db: Db;
    gamesCount: number;
    players: PlayerEntity[];
    error: string;
}