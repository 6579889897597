import { useEffect, useState } from 'react';

export const useConnectionHandler = () => {
    const [connected, setConnected] = useState(false);
    useEffect(() => {
        window.addEventListener('connection', event => {
            const connectionStatus = (event as any).detail as string;
            setConnected(connectionStatus === 'on');
        }, false);
    }, [setConnected]);

    return connected;
}