import React, { useEffect, useState } from 'react';
import { Form, Stack, Table } from 'react-bootstrap';
import { Db } from '../service/db';
import { PlayerEntity, PlayerProfile } from '../service/types';
import {
    calculate3bet,
    calculateAts,
    calculateCbet,
    calculateCToCbet,
    calculateFToCbet,
    calculatePfr,
    calculateRToCbet,
    calculateVpip,
    calculateWsd,
    calculateWt
} from '../service/profiler';
import { PlayerRow } from './PlayerRow';
import css from './style.module.scss';

export const PlayerProfiles: React.FC<{ db: Db, players: PlayerEntity[], count: number }> = ({db, players, count}) => {
    const [playerProfiles, setPlayerProfiles] = useState<PlayerProfile[]>([]);
    useEffect(() => {
        setPlayerProfiles(prevState => {
            return prevState.filter(pp => players.map(p => p.id).includes(pp.id));
        })
        players.forEach(player => db.getGamesByPlayer(player.id).then(games => {
            if (games.length === 0) {
                return;
            }
            const preflop = {
                vpip: calculateVpip(player.id, games),
                pfr: calculatePfr(player.id, games),
                ats: calculateAts(player.id, games),
                threeBet: calculate3bet(player.id, games)
            };
            const flop = {
                cbet: calculateCbet(player.id, games, 'flop'),
                fcb: calculateFToCbet(player.id, games, 'flop'),
                ccb: calculateCToCbet(player.id, games, 'flop'),
                rcb: calculateRToCbet(player.id, games, 'flop'),
            }

            const turn = {
                cbet: calculateCbet(player.id, games, 'turn'),
                fcb: calculateFToCbet(player.id, games, 'turn'),
                ccb: calculateCToCbet(player.id, games, 'turn'),
                rcb: calculateRToCbet(player.id, games, 'turn'),
            }

            const river = {
                wt: calculateWt(player.id, games),
                wsd: calculateWsd(player.id, games)
            }

            setPlayerProfiles(prevState => {
                const existing = prevState.find(p => p.id === player.id);
                if (existing) {
                    existing.preflop = preflop;
                    existing.flop = flop;
                    existing.turn = turn;
                    existing.games = games;
                    existing.river = river;
                    existing.totalGames = games.length;
                    return prevState;
                }
                const profile: PlayerProfile = {
                    id: player.id,
                    name: player.name,
                    games,
                    preflop,
                    flop,
                    turn,
                    river,
                    totalGames: games.length
                }
                return [...prevState, profile]
            })
        }));
    }, [db, players, count])
    return (
        <div
            className={players.length > 0 ? css.centeredContainer : undefined}
            style={{height: '100%', background: '#333333'}}
        >
            <div style={{minWidth: '80%'}}>
                <Stack direction='horizontal' gap={2} style={{height: '4em', marginLeft: '0.2em', color: 'whitesmoke'}}>
                    <Form.Label column style={{maxWidth: 'fit-content', fontWeight: 'bold'}}>
                        Players on the current table
                    </Form.Label>
                </Stack>
                <Table striped bordered variant="dark">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>TOTAL</th>
                        <th colSpan={2}>Pre-flop</th>
                        <th colSpan={2}>Flop</th>
                        <th colSpan={2}>Turn</th>
                        <th colSpan={2}>River</th>
                    </tr>
                    </thead>
                    <tbody>
                    {playerProfiles.sort((a, b) => b.preflop.vpip - a.preflop.vpip)
                        .map(profile => <PlayerRow profile={profile}/>)}
                    </tbody>
                </Table>
                {playerProfiles.length === 0 &&
                    <div className={css.centeredContainer}>
                        <Form.Label column style={{maxWidth: 'fit-content', fontWeight: 'bold', color: 'whitesmoke'}}>
                            Profiles may take up to 5 minutes after connection to appear.
                        </Form.Label>
                    </div>
                }
            </div>
        </div>
    );
}