import { User } from './context';
import * as jose from 'jose';

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqcqaVz70N6f7W4hmZNZM
2lCDiVlmqQPIBj6/KR7IaDe8mC5oChQg92EzaB8POjwOryZzROCOT2eABZ0lAiRl
Ou/aKtXxrWUkCdHmd0ZgxOf/KffCV5bcx6dz1PhOAsGn/VQceepsqRQR49hgdPc2
m3lZvbC49rDfJl661luKNt3yfY4E5OegK+j+nPhT7bDnIpFrDgaiBQs/TiTAEJV6
lkXPcZ9TEaQkaRiSZa7bwVS60I1x2aQZnh0JrDkc1UUvd5IuJguA8pRvRvCvYsdw
ArrwXsQkPD+vk3qYf1H75dFeyDqBvp42kgUoLoISrtH/tUkUWfEziVDC6jAg8Y0l
JwIDAQAB
-----END PUBLIC KEY-----`

const domain = process.env.REACT_APP_HOST;

interface UserResponse {
    id: string;
    name: string;
    token: string;
}

const verityToken = async (token: string) => {
    try {
        const key = await jose.importSPKI(publicKey, 'RS256');
        const {payload} = await jose.jwtVerify(token, key, {
            clockTolerance: 30,
            maxTokenAge: 86400,
            requiredClaims: ['subscribed']
        });
        return payload;
    } catch (e) {
        console.log('Failed to verify token:');
        console.log(e);
        throw e;
    }
}

const validateUserResponse = async (data: UserResponse) => {
    const payload = await verityToken(data.token);
    if (payload.sub !== data.id) {
        throw new Error('Invalid server response: id');
    }
    window.localStorage.setItem('token', data.token);
    return {
        id: data.id,
        name: data.name,
        hasSubscription: (payload as any).subscribed
    }
}
export const authenticate = async (userData: any): Promise<User> => {
    const rawResponse = await fetch(`${domain}/login`, {
        method: 'POST',
        headers,
        body: JSON.stringify(userData)
    });
    const data: UserResponse = await rawResponse.json();
    return validateUserResponse(data);
}

export const fetchUser = async (): Promise<User> => {
    const token = window.localStorage.getItem('token');
    if (!token) {
        console.log('Auth token not found');
        throw new Error('Auth token not found');
    }
    const rawResponse = await fetch(`${domain}/api/user`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            ...headers
        },
    });
    const data: UserResponse = await rawResponse.json();
    return validateUserResponse(data);
}