import { createContext } from 'react';
import { AppContextState } from './types';

export interface User {
    id: string;
    name: string;
    hasSubscription: boolean;
}
interface UserLoader {
    loadUser: () => Promise<void>;
    authenticateUser: (tgUser: any) => Promise<void>,
}
type UserContextValue = { user: User | null } & UserLoader;
export const UserContext = createContext<UserContextValue>({
    loadUser: () => Promise.resolve(),
    authenticateUser: (tgUser: any) => Promise.resolve(),
    user: null
});


export const ConnectionContext = createContext(false);

export const ApplicationContext = createContext<AppContextState | null>(null);