import { Card, suitColors, suitIcons } from '../service/types';
import { Badge } from 'react-bootstrap';
import React from 'react';

export const CardBadge: React.FC<{ card: Card }> = ({card}) =>
    <Badge
        bg="light"
        text={suitColors[card.suit]}
        style={{width: 'fit-content', fontWeight: 'bold'}}
    >
        {card.value}{suitIcons[card.suit]}
    </Badge>;