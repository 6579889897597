import React, { useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import css from './style.module.scss';
import { useNavigate } from 'react-router-dom';

const privacyPolicyLink = process.env.REACT_APP_PRIVACY_POLICY as string;
const videoGuide = process.env.REACT_APP_VIDEO_GUIDE as string;
export const Guide: React.FC = () => {
    const navigate = useNavigate();
    const handleSubmit = useCallback(() => {
        window.localStorage.setItem('consent', 'yes');
        navigate('/app');
    }, [navigate]);
    const handleNoConsent = () => {
        window.localStorage.removeItem('consent');
    }
    return (
        <div className={css.centeredContainer} style={{
            paddingTop: '1em',
            background: '#333333',
            borderTop: '1px solid gray',
            flexGrow: 1
        }}>
            <h5>How to start work with Lazy Pike</h5>
            <h6>for chrome browser on desktop</h6>
            <iframe src={videoGuide}
                    width="640"
                    height="350"
                    allow="autoplay"
                    style={{marginBottom: '1em'}}
            ></iframe>
            <ol style={{marginBottom: '0.5em'}}>
                <li>Open the following link -
                    <a
                        href='https://chromewebstore.google.com/detail/simple-tab-connector/ajechknomfdcokngpocbomeobgmmhgpo'
                        target='_blank'
                        rel="noreferrer"
                        style={{marginLeft: '0.5em'}}
                    >
                        Chrome Web Store
                    </a>.
                </li>
                <li>Add "Simple Tab Connector" extension to your Chrome browser.</li>
                <li>Pin it to be able to connect to gaming tables.</li>
                <li>Sit at a <b>no-limit hold'em</b> cash table in Tonpoker room.<br/>
                    We support 4-max and 6-max tables for now.
                </li>
                <li>Make sure that the tab with poker table and this tab are open in the same Chrome browser.</li>
                <li>On the current tab (lazypike.com) click on the extension icon
                    <img src='/assets/extension-icon.png' alt='Screenshot' style={{width: '2em'}}/>.
                </li>
                <li>In popup click on "Output" button, after that it should look like that.</li>
                <img src='/assets/extension-output.png' alt='Screenshot' style={{width: '5.5em'}}/>
                <li>Go to the tab with the poker table (play.tonpoker.online) and click on the extension icon
                    <img src='/assets/extension-icon.png' alt='Screenshot' style={{width: '2em'}}/>.
                </li>
                <li>In popup click on "Input" button, after that it should look like that.</li>
                <img src='/assets/extension-input.png' alt='Screenshot' style={{width: '5.5em'}}/>
                <li>Check on the current tab (lazypike.com) that the connection indicator in
                    <Form.Check checked readOnly type='radio' style={{display: 'inline', margin: '0.5em'}}></Form.Check>
                    state.
                </li>
            </ol>
            <Form
                className={css.centeredContainer}
                onSubmit={handleSubmit}
                onInvalid={handleNoConsent}
            >
                <div>
                    <Form.Check required
                                label={<span>Agree with <a href={privacyPolicyLink}>Privacy Policy</a>.</span>}
                                defaultChecked={!!window.localStorage.getItem('consent')}
                    ></Form.Check>
                </div>
                <Button
                    type='submit'
                    variant="dark"
                    style={{marginBottom: '0.5em'}}
                >Done</Button>
            </Form>
        </div>
    );
}