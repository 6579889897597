import { Action, GameEntity, Position } from './types';

export const calculateVpip = (playerId: string, games: GameEntity[]): number => {
    const activeGames = games.filter(game => {
        const actions = game.stages.preFlop?.actions ?? [];
        const voluntaryBets = actions.filter(act => act.player.id === playerId
            && act.action.type === 'bet'
            && act.action.subType !== 'blind'
        );
        return voluntaryBets.length > 0;
    });
    if (games.length === 0) {
        return 0;
    }
    return Math.round(activeGames.length * 100 / games.length);
}

export const calculatePfr = (playerId: string, games: GameEntity[]): number => {
    const filteredGames = games.filter(game => {
        const actions = game.stages.preFlop?.actions ?? [];
        const raises = actions.filter(act => act.player.id === playerId
            && act.action.type === 'bet'
            && act.action.subType === 'raise'
        );
        return raises.length > 0;
    });
    if (games.length === 0) {
        return 0;
    }
    return Math.round(filteredGames.length * 100 / games.length);
}


export const calculateAts = (playerId: string, games: GameEntity[]): number => {
    const gamesWithStealableCase = (
        playerId: string,
        lpGames: GameEntity[],
        subTypePredicate: (subtype: string | undefined) => boolean
    ) => {
        return lpGames.filter(game => {
            const actions = game.stages.preFlop?.actions ?? [];
            const actionsInStealableCase = actions.filter(act => act.player.id === playerId
                && subTypePredicate(act.action.subType)
                && actions.filter(a => a.player.id !== act.player.id
                    && a.action.type === 'bet'
                    && a.action.subType !== 'blind'
                    && a.order < act.order
                ).length === 0
            );
            return actionsInStealableCase.length > 0;
        });
    }
    const lpGames = games.filter(game => {
        const player = game.players.find(p => p.id === playerId);
        return player && player.position && [Position.CO, Position.BB, Position.SB].includes(player.position);
    });
    const stealableGames = gamesWithStealableCase(playerId, lpGames, subtype => subtype !== 'blind');
    const gamesWithStealing = gamesWithStealableCase(playerId, lpGames, subtype => subtype === 'raise');
    if (stealableGames.length === 0) {
        return 0;
    }
    return Math.round(gamesWithStealing.length * 100 / stealableGames.length);
}

export const calculate3bet = (playerId: string, games: GameEntity[]): number => {
    const gamesWithRaise = (
        playerId: string,
        games: GameEntity[],
        subTypePredicate: (subtype: string | undefined) => boolean
    ) => {
        return games.filter(game => {
            const actions = game.stages.preFlop?.actions ?? [];
            const actionsInRaisedCase = actions.filter(act => act.player.id === playerId
                && subTypePredicate(act.action.subType)
                && actions.filter(a => a.player.id !== act.player.id
                    && a.action.type === 'bet'
                    && a.action.subType === 'raise'
                    && a.order < act.order
                ).length === 1
            );
            return actionsInRaisedCase.length > 0;
        });
    }
    const allGames = gamesWithRaise(playerId, games, subtype => subtype !== 'blind');
    const filteredGames = gamesWithRaise(playerId, games, subtype => subtype === 'raise');
    if (allGames.length === 0) {
        return 0;
    }
    return Math.round(filteredGames.length * 100 / allGames.length);
}

export const calculateCbet = (playerId: string, games: GameEntity[], stage: 'flop' | 'turn' | 'river'): number => {
    const allGames = games
        .filter(game => getLastAggressorIdByStage(game, previousStage(stage)) === playerId)
        .filter(game => !!game.stages[stage])
    if (allGames.length === 0) {
        return 0;
    }
    const cbetGames = allGames.filter(game => {
        const actions = game.stages[stage]?.actions ?? [];
        const cbets = actions.filter(act => act.player.id === playerId && act.action.subType === 'raise');
        return cbets.length > 0;
    });
    return Math.round(cbetGames.length * 100 / allGames.length);
}

export const calculateFToCbet = (playerId: string, games: GameEntity[], stage: 'flop' | 'turn' | 'river') => {
    const predicate = (action?: Action) => action?.type === 'fold';
    return calculateReactionOnCbet(playerId, games, stage, predicate);
}

export const calculateRToCbet = (playerId: string, games: GameEntity[], stage: 'flop' | 'turn' | 'river') => {
    const predicate = (action?: Action) => action?.subType === 'raise';
    return calculateReactionOnCbet(playerId, games, stage, predicate);
}

export const calculateCToCbet = (playerId: string, games: GameEntity[], stage: 'flop' | 'turn' | 'river') => {
    const predicate = (action?: Action) => action?.subType === 'call';
    return calculateReactionOnCbet(playerId, games, stage, predicate);
}

const calculateReactionOnCbet = (playerId: string,
                                 games: GameEntity[],
                                 stage: 'flop' | 'turn' | 'river',
                                 predicate: (action?: Action) => boolean
): number => {
    const gamesAgainstAggressor = (gameScope: GameEntity[], actionPredicate?: (action?: Action) => boolean) => gameScope
        .filter(game => {
            const aggressorId = getLastAggressorIdByStage(game, previousStage(stage));
            if (!aggressorId || aggressorId === playerId) {
                return false;
            }
            const actions = game.stages[stage]?.actions ?? [];
            const playerActions = actions.filter(act => act.player.id === playerId);
            if (playerActions.length === 0) {
                return false;
            }
            const nextPlayerAction = (order: number) => {
                const sorted = playerActions.filter(
                    a => a.order > order
                ).sort((a, b) => a.order - b.order);
                return sorted.length > 0 ? sorted[0] : null;
            }
            const innerPredicate = (order: number) => {
                if (actionPredicate) {
                    return actionPredicate(nextPlayerAction(order)?.action);
                }
                return !!nextPlayerAction(order);
            }
            const aggressorRaisesBeforePlayer = actions.filter(act => act.player.id === aggressorId
                && act.action.subType === 'raise'
                && innerPredicate(act.order)
            );
            return aggressorRaisesBeforePlayer.length > 0;
        });
    const allGames = gamesAgainstAggressor(games);
    if (allGames.length === 0) {
        return 0;
    }
    const filteredGames = gamesAgainstAggressor(allGames, predicate);
    return Math.round(filteredGames.length * 100 / allGames.length);
}


const getLastAggressorIdByStage = (game: GameEntity, stage: 'flop' | 'turn' | 'preFlop'): string | null => {
    const actions = game.stages[stage]?.actions ?? [];
    const raises = actions.filter(act => act.action.type === 'bet' && act.action.subType === 'raise')
        .sort((a, b) => a.action.amount - b.action.amount);
    if (raises.length === 0) {
        return null;
    }
    return raises[raises.length - 1].player.id;
}
const previousStage = (stage: 'flop' | 'turn' | 'river') => {
    switch (stage) {
        case 'flop':
            return 'preFlop';
        case 'turn':
            return 'flop';
        case 'river':
            return 'turn';
    }
}

export const calculateWt = (playerId: string, games: GameEntity[]) => {
    const allGames = games.filter(game => {
        const preFlopActions = game.stages.preFlop?.actions ?? [];
        const fold = preFlopActions
            .filter(action => action.player.id === playerId && action.action.type === 'fold')
            .length > 0;
        const nonFold = preFlopActions
            .filter(action => action.player.id === playerId && action.action.type !== 'fold')
            .length > 0;
        return nonFold && !fold;
    });
    if (allGames.length === 0) {
        return 0;
    }
    const showdownGames = allGames.filter(game => {
        const player = game.players.find(pl => pl.id === playerId);
        return !!player && !player.inFold && !!player.cards && !!player.handName;
    })
    return Math.round(showdownGames.length * 100 / allGames.length);
}

export const calculateWsd = (playerId: string, games: GameEntity[]) => {
    const allGames = games.filter(game => {
        const playersOnShd = game.players
            .filter(player => !player.inFold && !!player.cards && !!player.handName)
            .map(pl => pl.id);
        return playersOnShd.length > 1 && playersOnShd.includes(playerId);
    });
    if (allGames.length === 0) {
        return 0;
    }
    const winGames = allGames.filter(game => {
        const isWinner = game.result?.won?.player.id === playerId;
        const inSplit = game.result?.split?.filter(winner => winner.player.id === playerId).length ?? 0;
        return isWinner || inSplit > 0;
    })
    return Math.round(winGames.length * 100 / allGames.length);
}