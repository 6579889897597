import { useCallback, useEffect, useState } from 'react';

interface ReloadingState {
    tabStatus: 'ready' | 'reloading';
    lastReloading: Date;
}

export const useReloading = () => {
    const [reloading, setReloading] = useState<ReloadingState>({
        tabStatus: 'ready',
        lastReloading: new Date(),
    });
    useEffect(() => {
        window.addEventListener('reloadingDone', event => {
            setReloading({
                lastReloading: new Date(),
                tabStatus: 'ready'
            });
        }, false);
    }, [setReloading]);

    const reloadProducer = useCallback((force: boolean) => {
        const diff = new Date().getTime() - reloading.lastReloading.getTime();
        if (reloading.tabStatus === 'ready' && (diff >= 300_000 || force)) {
            setReloading(prevState => ({
                lastReloading: prevState.lastReloading,
                tabStatus: 'reloading'
            }));
            window.dispatchEvent(new CustomEvent('requestReloading'));
        }
    }, [reloading, setReloading]);
    return {reloading, reloadProducer};
}