import React, { useCallback, useEffect, useState } from 'react';
import { authenticate, fetchUser } from '../service/api';
import { Parent } from '../service/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { TelegramUser } from 'telegram-login-button';
import { User, UserContext } from '../service/context';

const env = process.env.REACT_APP_ENV as string;
export const UserContextProvider: React.FC<Parent> = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState<User | null>(null);
    const loadUser = useCallback(() => fetchUser()
        .then(user => setUser(user))
        .catch(e => {
            setUser(null);
            console.log('Failed to load user');
            window.localStorage.removeItem('token');
            if (env !== 'dev') {
                navigate('/');
            }
        }), [setUser, navigate]);
    const authenticateUser = (tgUser: TelegramUser) => authenticate(tgUser)
        .then(response => setUser(response))
        .then(() => {
            const consent = window.localStorage.getItem('consent');
            navigate(consent ? '/app' : '/app/guide');
        })
        .catch(e => {
            setUser(null);
            console.log('Failed to login');
        });
    useEffect(() => {
        if (user !== null) {
            return;
        }
        if (location.pathname === '/') {
            fetchUser()
                .then(user => setUser(user))
                .catch(e => {
                    setUser(null);
                    console.log('Failed to load user');
                    window.localStorage.removeItem('token');
                });
            return;
        }
        loadUser().then();
    }, [user, location.pathname, loadUser, setUser])
    return (
        <UserContext.Provider value={{loadUser, authenticateUser, user: user}}>
            {children}
        </UserContext.Provider>
    );
}