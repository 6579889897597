import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import { Root } from './Root';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<Root />);

