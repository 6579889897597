import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../service/context';

export const useAuthenticationCheck = (needSubscription?: boolean) => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    useEffect(() => {
        if (needSubscription && !user?.hasSubscription) {
            navigate("/");
        }
    }, [user]);
}