import { useRouteError } from 'react-router-dom';
import React from 'react';
import Image from 'react-bootstrap/Image';
import css from './style.module.scss';

export const ErrorPage: React.FC = ()  => {
    const error: any = useRouteError();
    return (
        <div className={css.centeredContainer}>
            <Image src="assets/pike-logo.svg" fluid width="10%"/>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error?.statusText || error?.message}</i>
            </p>
        </div>
    );
}