import { useCallback, useEffect, useMemo, useState } from 'react';
import { Limit } from '../service/types';
import { wsLog } from '../service/logger';

const protocol = "https://";
const png = ".png";
const jpg = ".jpg";
const imgLength = 4;
const delimiterLength = 3;
const roomIdPrefix = '"roomId":"';
const sbPrefix = '"sb":';
const bbPrefix = '"bb":';
const rakePrefix = '"RAKE_PERCENT":';
const configDelimiter = ",";

const getValueByPrefix = (rawData: string, prefix: string): number | undefined => {
    const prefixId = rawData.indexOf(prefix);
    if (prefixId < 0) {
        return;
    }
    const start = prefixId + prefix.length;
    const end = rawData.indexOf(configDelimiter, start);
    if (end < 0) {
        return;
    }
    const value = rawData.substring(start, end);
    return +value;
}

const getIdByLinkIndex = (rawData: string, nameIndex: number, name: string) => {
    const expectedLinkIndex = nameIndex + name.length + delimiterLength;
    const linkIndex = rawData.indexOf(protocol, nameIndex);
    if (expectedLinkIndex !== linkIndex) {
        wsLog(`Unexpected link index: is ${linkIndex}, but we expect ${expectedLinkIndex}`);
        return;
    }
    const pngIndex = rawData.indexOf(png, linkIndex);
    const jpgIndex = rawData.indexOf(jpg, linkIndex);
    const imgIndex = pngIndex < jpgIndex && pngIndex > 0 ? pngIndex : jpgIndex;
    if (imgIndex < 0) {
        wsLog(`Image not found for ${name}`);
        return;
    }
    const idIndex = imgIndex + imgLength + delimiterLength + 1;
    const idEnd = idIndex + 24;
    const id = rawData.substring(idIndex, idEnd);
    if (!(/^[A-Za-z0-9]*$/.test(id))) {
        wsLog(`[${new Date().toISOString()}] unexpected id format for name: ${name}, ${id}`);
        wsLog(`Full line from ${nameIndex} to ${idEnd}: ${rawData.substring(nameIndex, idEnd)}`);
        return;
    }
    return id;
}

interface RoomState {
    roomId: string;
    limit: Limit | undefined;
}

export const useRoomState = () => {
    const [rawData, setRawData] = useState('');
    const [roomState, setRoomState] = useState<RoomState>({roomId: '', limit: undefined});

    useEffect(() => {
        window.addEventListener('wsData', event => {
            try {
                const arr = (event as any).detail;
                wsLog(`ws message code: ${arr[0]}`);
                if (arr[0] !== 14) {
                    return;
                }
                arr.shift();
                const uintArr = new Uint8Array(arr);
                const decoder = new TextDecoder();
                const raw = decoder.decode(uintArr);
                console.log(`[${new Date().toISOString()}] got room data`);
                wsLog(raw);
                setRawData(raw);
            } catch (e) {
                console.log(e);
            }
        }, false);
    }, [setRawData]);

    useEffect(() => {
        const prefixId = rawData.indexOf(roomIdPrefix);
        if (prefixId < 0) {
            return;
        }
        const start = prefixId + roomIdPrefix.length;
        const roomId = rawData.substring(start, start + 24);
        if (!(/^[A-Za-z0-9]*$/.test(roomId))) {
            console.log(`[${new Date().toISOString()}] unexpected room id format: ${roomId}`);
            return;
        }
        const sb = getValueByPrefix(rawData, sbPrefix);
        const bb = getValueByPrefix(rawData, bbPrefix);
        const rake = getValueByPrefix(rawData, rakePrefix);
        if (sb && bb && rake) {
            const state = {
                roomId: roomId,
                limit: {sb, bb, rake}
            };
            console.log(`[${new Date().toISOString()}] parsed room state`);
            setRoomState(state);
        }
    }, [rawData, setRoomState]);

    const getPlayerId = useCallback((name: string) => {
        if (rawData.length === 0) {
            return;
        }
        try {
            for (let nameIndex = rawData.indexOf(name); nameIndex > 0; nameIndex = rawData.indexOf(name, nameIndex + name.length)) {
                if (nameIndex < 0) {
                    console.log(`Name not found: ${name}`);
                    return;
                }
                const id = getIdByLinkIndex(rawData, nameIndex, name);
                if (id) {
                    return id;
                }
            }
        } catch (e) {
            console.log(e);
            return;
        }
        return;
    }, [rawData]);

    return useMemo(() => ({...roomState, getPlayerId}), [roomState, getPlayerId]);
}