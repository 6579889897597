import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { UserContext } from '../service/context';
import { useNavigate } from 'react-router-dom';
import TelegramLoginButton from 'telegram-login-button';


export const Authenticator: React.FC = () => {
    const {authenticateUser, user} = useContext(UserContext);
    const navigate = useNavigate();
    if (user) {
        return <Button variant="dark" onClick={() => navigate('/app')}>Continue as {user.name}</Button>;
    }
    return (<>
            <TelegramLoginButton
                botName="LazyPikeBot"
                requestAccess={false}
                dataOnauth={(user) => authenticateUser(user)}
            />
            <p style={{textAlign: 'center', color: 'yellow', fontSize: '0.7em'}}>
                The Telegram login window may close after approval, <br/>
                so click the login button again to continue.</p>
        </>
    );
}