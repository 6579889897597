import React from 'react';
import { PlayerProfile } from '../service/types';


export const PlayerRow: React.FC<{ profile: PlayerProfile }> = ({profile}) => {
    return (
        <tr>
            <td>{profile.name}</td>
            <td>{profile.totalGames}</td>
            <td>
                <div>VPIP</div>
                <div>PFR</div>
                <div>ATS</div>
                <div>3-BET</div>
            </td>
            <td>
                <div>{profile.preflop.vpip}%</div>
                <div>{profile.preflop.pfr}%</div>
                <div>{profile.preflop.ats}%</div>
                <div>{profile.preflop.threeBet}%</div>
            </td>
            <td>
                <div>CB</div>
                <div>FCB</div>
                <div>CCB</div>
                <div>RCB</div>
            </td>
            <td>
                <div>{profile.flop.cbet}%</div>
                <div>{profile.flop.fcb}%</div>
                <div>{profile.flop.ccb}%</div>
                <div>{profile.flop.rcb}%</div>
            </td>
            <td>
                <div>CB</div>
                <div>FCB</div>
                <div>CCB</div>
                <div>RCB</div>
            </td>
            <td>
                <div>{profile.turn.cbet}%</div>
                <div>{profile.turn.fcb}%</div>
                <div>{profile.turn.ccb}%</div>
                <div>{profile.turn.rcb}%</div>
            </td>
            <td>
                <div>WT</div>
                <div>WSD</div>
            </td>
            <td>
                <div>{profile.river.wt}%</div>
                <div>{profile.river.wsd}%</div>
            </td>
        </tr>
    );
}