import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface GlobalControlsProps {
    connected: boolean
}

export const GlobalControls: React.FC<GlobalControlsProps> = ({connected}) => {
    return (
        <Form>
            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip>{connected ? 'Connected to the table' : 'Disconnected from the table'}</Tooltip>}
            >
                {({ref, ...triggerHandler}) => (
                    <Form.Check
                        readOnly
                        autoFocus={!connected}
                        ref={ref}
                        type='radio'
                        checked={connected}
                        {...triggerHandler}
                    />
                )}
            </OverlayTrigger>
        </Form>
    );
}