import { useEffect, useState } from 'react';
import { Db, initDb } from '../service/db';


export const useDb = () => {
    const [db, setDb] = useState<Db>();
    useEffect(() => {
        console.log('init db');
        initDb().then(database => setDb(database));
    }, []);
    return db;
}