import React from 'react';
import { Badge, Stack } from 'react-bootstrap';
import { Card, Stage } from '../service/types';
import { CardBadge } from './CardBadge';


type Props = {
    stage?: Stage;
    cards?: Card[];
};
export const StageHeader: React.FC<Props> = ({stage, cards}) => {
    if (!stage || !cards) {
        return <div/>;
    }
    return (
        <Stack direction="vertical" gap={1} className='mx-auto'>
            <Badge bg="warning" text="dark" style={{width: 'fit-content'}}>{stage.pot}</Badge>
            <Stack direction="horizontal" gap={2}>
                {cards.map(card => <CardBadge card={card} />)}
            </Stack>
        </Stack>
    );
}